import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faDownload, faEllipsisV, faEye, faFile, faPen, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getUserToken, logout, validateToken } from '../../controllers/user.controller';
import { GET, POST } from '../../controllers/endpoints.controller';


function CurriculumYears() {
    const PAGE_TITLE = "Deploy";
    const PAGE_ICON = faCog;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
    }, []);

    //Modal
    const [showDeployLogModal, setShowDeployLogModal] = useState(false);
    const [deployLog, setDeployLog] = useState("");

    const handleShowDeployLogModal = (microservice) => {
        setDeployLog("Cargando...")
        setShowDeployLogModal(true)
        postDeploy(microservice)
    };

    const handleCloseDeployLogModal = () => {
        setShowDeployLogModal(false);
    }

    const [microservices, setMicroservices] = useState([
        {
            name: 'controller',
            server: 'ar-caba-sv204',
            ip: '10.0.102.4'
        },
        {
            name: 'grades',
            server: 'ar-caba-sv205',
            ip: '10.0.102.5'
        },
        {
            name: 'classes',
            server: 'ar-caba-sv206',
            ip: '10.0.102.6'
        },
        {
            name: 'schools',
            server: 'ar-caba-sv207',
            ip: '10.0.102.7'
        },
        {
            name: 'sso',
            server: 'ar-caba-sv208',
            ip: '10.0.102.8'
        },
        {
            name: 'curriculum',
            server: 'ar-caba-sv209',
            ip: '10.0.102.9'
        },
        {
            name: 'assistance',
            server: 'ar-caba-sv210',
            ip: '10.0.102.10'
        },
        {
            name: 'communications',
            server: 'ar-caba-sv211',
            ip: '10.0.102.11'
        },
        {
            name: 'students',
            server: 'ar-caba-sv212',
            ip: '10.0.102.12'
        },
        {
            name: 'employees',
            server: 'ar-caba-sv213',
            ip: '10.0.102.13'
        },
        {
            name: 'calendar',
            server: 'ar-caba-sv214',
            ip: '10.0.102.14'
        },
        {
            name: 'files',
            server: 'ar-caba-sv215',
            ip: '10.0.102.15'
        },
        {
            name: 'logging',
            server: 'ar-caba-sv216',
            ip: '10.0.102.16'
        },
        {
            name: 'registration',
            server: 'ar-caba-sv217',
            ip: '10.0.102.17'
        },
        {
            name: 'notifications',
            server: 'ar-caba-sv218',
            ip: '10.0.102.18'
        },
        {
            name: 'sanctions',
            server: 'ar-caba-sv221',
            ip: '10.0.102.21'
        },
        {
            name: 'academic-record',
            server: 'ar-caba-sv222',
            ip: '10.0.102.22'
        }
    ])

    const postDeploy = async function (microservice) {
        const body = {
            microservices: [
                microservice
            ]
        }

        const req = await POST(`/deploy`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            setDeployLog(res.data)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <div className="content">
                <Row className='m-0'>
                    <Col>
                        <Card className='cards mt-3'>
                            <div>
                                <img className="mb-3" src="/logo_color.svg" width={110} alt="Tasky Logo" />

                                <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                    <Col md={5} className='align-center-vertically-v2 ms-0'>
                                        <div>
                                            Name
                                        </div>
                                    </Col>

                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            Host
                                        </div>
                                    </Col>

                                    <Col md={2} className='align-center-vertically-v2 ms-0'>
                                        <div style={{ textAlign: 'center' }}>
                                            IP
                                        </div>
                                    </Col>

                                    <Col md={3} className='align-center-vertically-v2 ms-0'>

                                    </Col>
                                </Row>
                            </div>

                            {microservices.map((m, i) => (
                                <div key={i}>
                                    <div className='d-none d-sm-block'>
                                        <Row className='mb-1 pb-1 pt-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={5} className='align-center-vertically-v2 ms-0'>
                                                <div style={{ textAlign: 'left' }}>
                                                    {m.name}
                                                </div>
                                            </Col>
                                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {m.server}
                                                </div>
                                            </Col>
                                            <Col md={2} className='align-center-vertically-v2 ms-0'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {m.ip}
                                                </div>
                                            </Col>


                                            <Col md={3} className='align-center-vertically-v2 ms-0'>
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button variant="primary" onClick={() => handleShowDeployLogModal(m.name)}>
                                                        <span><FontAwesomeIcon icon={faDownload} className='me-2' />Deploy</span>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div>

            <Modal size='xl' show={showDeployLogModal} onHide={handleCloseDeployLogModal} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCog} style={{ minWidht: '20px' }} /><Modal.Title>Deploy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className='m-3'>
                            <div dangerouslySetInnerHTML={{ __html: deployLog }} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseDeployLogModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Listo</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CurriculumYears